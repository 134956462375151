
import { Vue, Component, Watch } from "vue-property-decorator";
import Base from "./Base";

import { getContract, getProvider } from "../lib/blockchain-helper";
import ContractMonitor from "../lib/contract-monitor";
import Big from "bignumber.js";
import { BigNumber } from "ethers/utils";

import SwapInner from "./_swap.vue";
import LiquidityInner from "./_liquidity.vue";
import { Contract } from "ethers";
import MixinMenu from "./_mixin_menu";

import network from "../network";
import { JsonRpcProvider } from "ethers/providers";
import { getTokens } from "./Adm.vue";
import staticCoins from "./static-coins";

@Component({
    mixins: [MixinMenu],
    components: { SwapInner, LiquidityInner },
})
export default class Swap extends Base {
    tab = "swap";

    coins: {
        id: string;
        name: string;
        url: string;
        tokenAddress: string;
        decimalPlace: number;
        exchangeAddress: string;
    }[] = staticCoins;

    liquidityLoading = false;

    liquidities: {
        [tokenId: string]: {
            major: Big;
            minor: Big;
        };
    } = {};

    swappers: {
        [tokenId: string]: {
            swapper: Contract;
            usd: Contract;
        };
    } = {};

    provider: JsonRpcProvider | null = null;

    loading = true;
    loadingToken: any = Promise.resolve();

    // async setMaxAmount() {
    //     const provider = this.getProvider(false);
    //     const signer = provider.getSigner();
    //     try {
    //         const account = await signer.getAddress();
    //         const router = getContract(network, "Router", signer);
    //         console.log('owner', await router.owner(), account, router.address, await router.isOwner());
    //         const pid = await router.tokenAddressToId("0x2e6b163D5bA678515A6a0D27e063eA794d79d975");
    //         console.log('pid', pid.toString());

    //         const t = await router.setSwapperMaxAmount(pid, new Big(25000).times(1e8).toString());
    //         console.log(t.hash);
    //     } catch (error) {
    //         console.log(error.message);
    //     }
    // }

    cleanUp() {
        for (const swapperIndex in this.swappers) {
            this.swappers[swapperIndex].swapper.removeAllListeners(
                "LiquidityChange"
            );
        }

        this.provider?.removeAllListeners();
    }

    beforeDestroy() {
        this.cleanUp();
    }

    async mounted() {
        this.provider = this.getProvider(true);

        if (network == "development") {
            this.coins = [];

            const provider = this.getProvider(true);
            if (provider) {
                const coins = await getTokens(provider);
                if (coins) this.coins = coins as any;
            }
        }
    }

    fetchLiquidity(
        tokenId: string,
        swapperAddress: string,
        tokenAddress: string,
        tokendp: number
    ) {
        this.liquidityLoading = true;
        console.log("fetching liquidity", tokenId);

        this.$nextTick(async function () {
            try {
                if (!(network && this.provider))
                    throw "provider|network not specified";

                if (!this.liquidities[tokenId]) {
                    const swapper = getContract(
                        network,
                        "Swapper",
                        this.provider,
                        swapperAddress
                    );

                    const usd = getContract(
                        network,
                        "EGG",
                        this.provider,
                        tokenAddress
                    );

                    this.swappers[tokenId] = {
                        swapper,
                        usd,
                    };

                    const major: BigNumber = await this.provider.getBalance(
                        swapperAddress
                    );
                    const minor: BigNumber = await usd.balanceOf(
                        swapperAddress
                    );

                    this.liquidities = {
                        ...this.liquidities,
                        [tokenId]: {
                            major: new Big(major.toString()).div(1e18),
                            minor: new Big(minor.toString()).div(
                                `1e${tokendp}`
                            ),
                        },
                    };

                    swapper.on(
                        "LiquidityChange",
                        (major: BigNumber, minor: BigNumber) => {
                            console.log("liquidity change detected!");

                            this.liquidities = {
                                ...this.liquidities,
                                [tokenId]: {
                                    major: new Big(major.toString()).div(1e18),
                                    minor: new Big(minor.toString()).div(
                                        `1e${tokendp}`
                                    ),
                                },
                            };
                        }
                    );
                }
            } catch (error) {
                console.error("when fetching liquidity", swapperAddress, error);
            } finally {
                this.liquidityLoading = false;
            }
        });
    }
}
