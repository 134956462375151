
import { Component } from "vue-property-decorator";
import { getContract } from "../lib/blockchain-helper";
import network from "../network";
import Base from "./Base";
import Big from "bignumber.js";
import { BigNumber } from "ethers/utils";
import { JsonRpcProvider } from "ethers/providers";

function toEther(v: any, dp = 18) {
    return new Big(v).div(`1e${dp}`);
}

// why we export this functions? so that we could use it on other module..
export async function getFarms(provider: JsonRpcProvider | null) {
    if (provider) {
        let contract;

        if (network == "development") {
            contract = getContract(network, "Farms", provider);
        } else {
            contract = getContract(
                network,
                "Farms",
                provider,
                "0x081e08Fc40c5b06659B22B8E66B652D6f370ae88"
            );
        }

        const farms: any = {};

        farms.weightTotal = (await contract.weightTotal()).toNumber();
        farms.pools = [];
        farms.contractAddress = contract.address;

        const pid: BigNumber = await contract.poolId();

        for (let i = 1; i <= pid.toNumber(); i++) {
            const pool = await contract.pools(i);

            // the token
            const swapper = getContract(
                network,
                "Swapper",
                provider,
                pool.token
            );

            const [lpName, lpSymbol]: [string, string] = await Promise.all([
                swapper.name(),
                swapper.symbol(),
            ]);

            const poolItem = {
                id: i.toString(),
                active: true,
                lpName,
                lpSymbol,
                lpAddress: pool.token,
                weight: toEther(pool.weight, 0).toNumber(),
                startBlock: toEther(pool.startBlock, 0).toNumber(),
                endBlock: toEther(pool.endBlock, 0).toNumber(),
            };

            farms.pools.push(poolItem);
        }

        return farms;
    }
}

export async function getTokens(provider: JsonRpcProvider | null) {
    if (provider) {
        const coins = [
            {
                id: "TT",
                name: "Thunder Token",
                tokenAddress: "TT",
                decimalPlace: 18,
                exchangeAddress: "",
            },
        ];

        const router = getContract(network, "Router", provider);

        let tokenIdResult: [boolean, BigNumber] = await router.firstTokenId();

        while (tokenIdResult && tokenIdResult[1].gt(0)) {
            const tid = tokenIdResult[1];

            const token = await router.tokens(tid);

            const erc20 = getContract(network, "ERC20", provider, token);

            // console.log(tid.toString(), token);

            const [swapperAddress, name, symbol, decimals]: [
                string,
                string,
                string,
                number
            ] = await Promise.all([
                router.exchanges(tid),
                erc20.name(),
                erc20.symbol(),
                erc20.decimals(),
            ]);

            coins.push({
                id: symbol,
                name: name,
                tokenAddress: token,
                decimalPlace: decimals,
                exchangeAddress: swapperAddress,
            });

            tokenIdResult = await router.nextTokenId(tid);
        }

        return coins;
    }
}

@Component({})
export default class Adm extends Base {
    loading = false;

    farms: {
        weightTotal: number;
        pools: any[];
    } = {
        weightTotal: 0,
        pools: [],
    };

    coins = [
        {
            id: "TT",
            name: "Thunder Token",
            tokenAddress: "TT",
            decimalPlace: 18,
            exchangeAddress: "",
        },
    ];

    async getFarms() {
        this.loading = true;
        try {
            const provider = this.getProvider(true);
            if (provider) {
                this.farms = (await getFarms(provider)) as any;
                const egg = getContract(network, "EGG", provider);
                console.log(await egg.owner());
            }
        } catch (error) {
            console.error(error);
        }
        this.loading = false;
    }

    async getTokens() {
        this.loading = true;
        try {
            this.coins = (await getTokens(this.getProvider(true))) as any;
        } catch (error) {
            console.error(error);
        }
        this.loading = false;
    }
}
