import { Vue, Component } from 'vue-property-decorator';
import Big from "bignumber.js";
import { BigNumber } from 'ethers/utils';
import { getProvider } from '@/lib/blockchain-helper';
import { JsonRpcProvider } from 'ethers/providers';

import network from "../network";

@Component({})
export default class Base extends Vue {
    toEther(v: BigNumber, dp = 18) {
        return new Big(v.toString()).div(`1e${dp}`);
    }

    getProvider(forMonitoring = false): JsonRpcProvider | null {
        if (network == "development") {
            const p = getProvider("development", forMonitoring);
            if (p) {
                p.pollingInterval = 1000;
            }

            return p;
        } else {
            const provider = this.$store.state.provider;

            const p = getProvider(
                provider,
                forMonitoring
            );

            if (p) {
                p.pollingInterval = 1000;
            }

            return p;
        }
    }
}