import { JsonRpcProvider, Provider, Web3Provider } from 'ethers/providers';
import { Contract } from 'ethers/contract';

import contractSpec from "../contract-addresses.json";
import innerGateway from "../inner-gateway.json";

import ERC20Abi from "./erc20-abi";

import { Signer } from 'ethers';
import { parseUnits } from "ethers/utils";

export function txFee() {
    return {
        gasPrice: parseUnits("1100", "gwei"),
    }
}

export function getContract(network: string, contractName: string, provider: Provider | Signer, atAddress?: string) {
    let abi;
    let address;

    if (contractName == "ERC20") {
        if (!atAddress) throw "specifying ERC20 without token address";
        abi = ERC20Abi;
        address = atAddress;
    } else if (contractName == "GATEWAY") {
        abi = innerGateway.abi;
        address = innerGateway.address;
    } else {
        const networkLevel = (contractSpec as any)[network];

        if (!networkLevel) throw `check network: ${network}`

        const spec = networkLevel[contractName];

        if (!spec) {
            throw `contract:${contractName} not found at network: ${network}`;
        }

        abi = spec.abi;
        address = atAddress || spec.address
    }

    const contract = new Contract(address, abi, provider);

    return contract;
}

export function getProvider(providerName = '', forMonitoring = true): JsonRpcProvider | null {
    if (providerName == 'development' || providerName == "local") {
        const p = new JsonRpcProvider("http://localhost:8545");
        p.pollingInterval = 1000;
        return p;
    } else {
        const win = window as any;

        if (forMonitoring) {
            const p = new JsonRpcProvider("https://mainnet-rpc.thundercore.com");
            p.pollingInterval = 1000;
            return p;
        }

        if (providerName) {
            if (!win[providerName]) throw new Error('invalid provider name:' + providerName);

            if (providerName == 'web3') {
                return new Web3Provider(win[providerName].currentProvider)
            } else {
                return new Web3Provider(win[providerName])
            }
        }
    }

    return null;
}
