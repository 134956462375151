
import web3mixin from "../web3-mixin";
import { Component } from "vue-property-decorator";

@Component({})
export default class Login extends web3mixin {
    async login(provider: string) {
        const w = this.wallets.filter((v) => v.provider == provider);
        const wallet = w[0];

        if (!wallet.accounts || wallet.accounts.length == 0) {
            try {
                await this.loginWeb3(wallet.provider);
            } catch (error) {
                (window as any).$notify("login web3 failed", "danger");
            }
        } else {
            this.setActiveProvider(provider, 0, false);
        }

        this.$emit("login");
    }
}
