import * as ethers from "ethers";
import { Web3Provider } from "ethers/providers";
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class Web3Mixin extends Vue {
    selectedProvider = -1;
    walletProviders: string[] = [];
    wallets: { accounts: string[]; provider: string; netID: number }[] = [];
    detectingWallet = true;

    async mounted() {
        const win: any = window;

        const knowProviders = 'thundercore,ethereum,web3'.split(',');

        const chosenProvider = (this as any).$store.state.provider;

        // try {
        //     const r = await fetch("http://localhost:8545");
        //     if (r.status == 400) {
        //         const p = new ethers.providers.JsonRpcProvider("http://localhost:8545");
        //         try {
        //             const accounts = await p.listAccounts();
        //             const netID = (await p.getNetwork()).chainId;
    
        //             this.walletProviders.push("local");
        //             this.wallets.push({ accounts, provider: "local", netID });
        //         } catch (error) {
        //             console.log("error ganache", error.toString());
        //         }
        //     }
        // } catch (error) {
        //     // do nothing..
        // }

        for (const provider of knowProviders) {
            if (win[provider]) {
                this.walletProviders.push(provider);

                try {
                    let p;
                    if (provider == 'web3') {
                        p = new ethers.providers.Web3Provider(win[provider].currentProvider);
                    } else {
                        p = new ethers.providers.Web3Provider(win[provider]);
                    }
                    const accounts = await p.listAccounts();
                    const netID = (await p.getNetwork()).chainId;

                    this.wallets.push({ accounts, provider, netID });

                    if (accounts[0]) {
                        if (chosenProvider == provider) {
                            this.setActiveProvider(provider, 0, true);
                        }
                    }
                } catch (error) {
                    console.log(`error ${provider}, ${error}`);
                }
            }
        }

        this.detectingWallet = false;

        if (!this.$store.state.provider) {
            // if wallets length is 1 and we are having the account, it mean we already loged in
            // thundercorehub
            const withAccounts = this.wallets.filter((v: any) => v.accounts.length > 0);

            if (withAccounts.length == 1) {
                // already loged in..
                this.setActiveProvider(withAccounts[0].provider, 0, true);
            }
        }
    }

    setActiveProvider(provider: string, accountNumber = 0, monitorEvent = false) {
        const w = this.wallets.filter((v: any) => v.provider == provider);
        const wallet = w[0];

        if (wallet) {
            // console.log("active provider set..", provider, wallet.accounts);
            this.$store.commit("provider", provider);
            this.$store.commit("account", wallet.accounts[0]);
            this.$store.commit("chainId", wallet.netID);

            if (monitorEvent && provider !== 'local') {
                const win = window as any;
                win[provider].on("accountsChanged", (accounts: string[]) => {
                    this.$store.commit("account", accounts[0]);
                })
                win[provider].on("chainChanged", (chainId: any) => {
                    this.$store.commit("chainId", chainId);
                })
            }
        }
    }

    translateProvider(provider: string) {
        switch (provider) {
            case "thundercore": return "ThunderLink";
            case "ethereum": return "MetaMask";
            case "web3": return "Web3";
            case "local": return "Local";
        }
        return "[UNKNOWN] Wallet"
    }

    async loginWeb3(provider: string) {
        const win = window as any;

        if (this.walletProviders.filter(v => v == provider).length == 0) {
            return alert(`wallet provider: ${provider} does not exist`);
        }

        try {
            if (provider != 'web3') {
                // metamask, thunderlink
                await win[provider].enable();

                // get accounts..
                const p = new Web3Provider(win[provider]);
                const s = p.getSigner();
                const account = await s.getAddress();

                const wIndex = this.wallets.findIndex((v: any) => v.provider == provider);
                const wallet = this.wallets[wIndex];

                wallet.accounts = [account];
                this.wallets.splice(wIndex, 1, wallet);
            }

            this.setActiveProvider(provider, 0, true);
            return true;
        } catch (error) {
            return false;
        }
    }
}
