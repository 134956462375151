
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Base from "../views/Base";

import Big from "bignumber.js";
import { Provider } from "ethers/providers";
import { Contract } from "ethers";
import { BigNumber } from "ethers/utils";
import { getContract } from "../lib/blockchain-helper";
import NumberDisplay from "./number-display.vue";
import network from "../network";

@Component({
  components: { NumberDisplay },
})
export default class Balance extends Base {
  @Prop({ required: true, default: 3 })
  readonly displayDp: number | undefined;

  @Prop({})
  readonly token: string | undefined;

  @Prop()
  readonly address: string | undefined;

  @Prop()
  readonly provider: Provider | undefined;

  balance = new Big(0);
  loading = true;
  watchAddress = "";

  @Watch("token")
  onTokenChange() {
    this.loadBalance();
  }

  @Watch("address")
  async onAddressChange() {
    await this.loadingBalance;
    this.loadingBalance = this.loadBalance();
  }

  contract: Contract | undefined;

  loadingBalance: any = null;

  beforeDestory() {
    this.cleanUp();
  }

  mounted() {
    this.loadingBalance = this.loadBalance();
  }

  cleanUp() {
    if (this.watchAddress) this.provider?.removeAllListeners(this.watchAddress);

    this.contract?.removeAllListeners("Transfer");
  }

  clicked() {
    this.$emit("click", this.balance);
  }

  loadBalance(): Promise<void> {
    this.loading = true;
    return new Promise((resolve) => {
      this.$nextTick(async function () {
        try {
          if (!(this.provider && this.token && this.address))
            throw "provider|token|address not set";

          this.cleanUp();

          this.watchAddress = this.address;

          if (this.token == "TT" && this.address) {
            const getBalance = async () => {
              if (!(this.address && this.provider)) return;

              const b: BigNumber = await this.provider.getBalance(this.address);

              this.balance = new Big(b.toString()).div(1e18);
            };

            getBalance();

            this.provider.on(this.address, (b: BigNumber) => {
              this.balance = new Big(b.toString()).div(1e18);
            });
          } else {
            const contract = getContract(
              network,
              "ERC20",
              this.provider,
              this.token
            );

            const decimals: number = await contract.decimals();

            const getBalance = async () => {
              const b: BigNumber = await contract.balanceOf(this.address);
              this.balance = new Big(b.toString()).div(`1e${decimals}`);
            };

            contract.on("Transfer", (from: string, to: string) => {
              if (from == this.address || to == this.address) {
                getBalance();
              }
            });

            getBalance();
          }
        } catch (error) {
          console.log("error when fetching balance", error);
        } finally {
          this.loading = false;
          resolve();
        }
      });
    });
  }
}
