import { getContract, getProvider } from '@/lib/blockchain-helper';
import Vue from "vue";
import Vuex from "vuex";
import Big from "bignumber.js";
import network from '@/network';
Big.config({ EXPONENTIAL_AT: 256 })

function toEther(v: any, dp = 18) {
    return new Big(v.toString()).div(`1e${dp}`);
}

Vue.use(Vuex);

const localStorageName = 'zzzswap_setup'
const p = getProvider("xxx", true);

export default new Vuex.Store({
    state: {
        account: '',
        provider: '',
        chainId: '',
        routerLoading: true,
        blockchainProgress: '',
        eggPrice: new Big(0),
        ttPrice: new Big(0),
        vol24h: {},
    },
    mutations: {
        chainId(state, value: string) {
            state.chainId = value;
        },
        account(state, value: string) {
            localStorage.removeItem(localStorageName + "account")
            if (value)
                localStorage.setItem(localStorageName + "account", value);

            state.account = value
        },
        provider(state, value: string) {
            localStorage.removeItem(localStorageName + "provider")
            if (value)
                localStorage.setItem(localStorageName + "provider", value);

            state.provider = value
        },
        routerLoading(state, value: boolean) {
            state.routerLoading = value;
        },
        blockchainProgress(state, value: string) {
            state.blockchainProgress = value;
        }
    },
    actions: {
        async checkStorage({ commit }) {
            const provider = localStorage.getItem(localStorageName + "provider");

            if (provider) {
                const p = getProvider(provider, false);

                if (p) {
                    commit("provider", provider);

                    const s = p.getSigner();
                    const chain = await p.getNetwork();
                    commit("chainId", chain.chainId);

                    if (s) {
                        try {
                            const account = await s.getAddress();
                            commit("account", account);
                        } catch (error) {
                            console.log("not loged in")
                        }
                    }
                }
            }
        },

        async logout({ commit }) {
            commit("provider", null);
            commit("account", null);
        },

        destroyPriceMonitor() {
            if (p) {
                p.removeAllListeners();
            }
        },

        async fetchVol24h({state}) {
            try {
                // get volumes..
                const r = await fetch(
                    "https://stat.eggdex.com/aggregates/24hvolume.json"
                );
                const json = await r.json();
                for (const item of json) {
                    state.vol24h = {
                        ...state.vol24h,
                        [item.token]: new Big(item.sum),
                    };
                }
            } catch (error) {
                console.log("could not fetch volume", error.message);
            }
        },

        async checkPrice({ state, dispatch }) {
            // preparing for egg price..
            const egg = {
                id: "EGG2",
                name: "The Egg2",
                tokenAddress: "0x8eD8Ae1703ACb33031cBD464400b57978Fe501aB",
                decimalPlace: 18,
                exchangeAddress: "0x9744F1746BbF6A5A68470A50Dd3391cFC3e0061c",
            };

            const usdt = {
                id: "TT-USDT",
                name: "TT-USDT",
                tokenAddress: "0x4f3C8E20942461e2c3Bdd8311AC57B0c222f2b82",
                decimalPlace: 6,
                exchangeAddress: "0xF5cDF7f965Fb1Ae4E27fbA16AaDbA32195359138",
            };

            await dispatch("destroyPriceMonitor");

            if (p) {
                const contract = getContract(
                    network,
                    "Swapper",
                    p,
                    egg.exchangeAddress
                );

                const erc20 = getContract(network, "ERC20", p, egg.tokenAddress);

                const price = async () => {
                    const totalTT = toEther(await p.getBalance(contract.address));
                    const totalEGG = toEther(await erc20.balanceOf(contract.address));

                    state.eggPrice = totalTT.div(totalEGG);
                }

                price();

                p.on(contract.address, price);
            }

            if (p) {
                const contract = getContract(
                    network,
                    "Swapper",
                    p,
                    usdt.exchangeAddress
                );

                const erc20 = getContract(network, "ERC20", p, usdt.tokenAddress);

                const price = async () => {
                    const totalTT = toEther(await p.getBalance(contract.address));
                    const totalUSD = toEther(await erc20.balanceOf(contract.address), usdt.decimalPlace);

                    state.ttPrice = totalUSD.div(totalTT);
                }

                price();

                p.on(contract.address, price);
            }
        }
    },
    modules: {}
});
