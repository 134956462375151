
import { Vue, Component } from "vue-property-decorator";
import Login from "./views/Login.vue";
import Big from "bignumber.js";

@Component({
    components: { Login },
})
export default class App extends Vue {
    async mounted() {
        // check if already loged in
        await this.$store.dispatch("checkStorage");

        const provider = this.$store.state.provider;

        if (!((provider && provider == "local") || provider == "development")) {
            const win = window as any;
            if (provider && win[provider] && win[provider].on) {
                win[provider].on("accountsChanged", (accounts: string[]) => {
                    this.$store.commit("account", accounts[0]);
                });
                win[provider].on("chainChanged", (chainId: any) => {
                    this.$store.commit("chainId", chainId);
                });
            }
        }

        this.$store.dispatch("checkPrice");
        this.$store.dispatch("fetchVol24h");
    }

    beforeDestroy() {
        this.$store.dispatch("destroyPriceMonitor");
    }

    logout() {
        this.$store.dispatch("logout");
    }

    connect() {
        const win = window as any;
        win.jQuery("#connect-dialog").modal("show");
    }

    closeLogin() {
        const win = window as any;
        win.jQuery("#connect-dialog").modal("hide");
    }

    totalVolume() {
        let b = new Big(0);
        for (const key in this.$store.state.vol24h) {
            b = b.plus(this.$store.state.vol24h[key]);
        }
        return b;
    }
}
