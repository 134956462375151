import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Swal, { SweetAlertIcon } from "sweetalert2";

import Big from "bignumber.js";
Big.config({ EXPONENTIAL_AT: 256 });

Vue.config.productionTip = false;

window.swal = function (icon: SweetAlertIcon, message: string) {
  Swal.fire({
    toast: true,
    position: 'top',
    width: '100%',
    timer: 15000,
    icon: "error",
    timerProgressBar: true,
    html: '<span class="ml-2">' + message + '</span>'
  });
}

Vue.prototype.$humanAddress = function (address: string) {
  return address.substr(0, 5) + "..." + address.substr(-4);
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
