export default [
    {
        id: "TT",
        url: "https://thundercore.com",
        name: "Thunder Token",
        tokenAddress: "TT",
        decimalPlace: 18,
        exchangeAddress: "",
    },
    {
        id: "EGG2",
        url: "https://eggdex.com",
        name: "The Egg2",
        tokenAddress: "0x8eD8Ae1703ACb33031cBD464400b57978Fe501aB",
        decimalPlace: 18,
        exchangeAddress: "0x9744F1746BbF6A5A68470A50Dd3391cFC3e0061c",
    },
    {
        id: "TT-USDT",
        url: "https://thundercore.com",
        name: "TT-USDT",
        tokenAddress: "0x4f3C8E20942461e2c3Bdd8311AC57B0c222f2b82",
        decimalPlace: 6,
        exchangeAddress: "0xF5cDF7f965Fb1Ae4E27fbA16AaDbA32195359138",
    },
    {
        id: "BUSD",
        url: "https://www.paxos.com/busd/",
        name: "Bep2BUSD-ON-TT",
        tokenAddress: "0x2e6b163D5bA678515A6a0D27e063eA794d79d975",
        decimalPlace: 8,
        exchangeAddress: "0xec875Fe173e95c64E95dDE005dEb46d45733f4cC",
    },
    {
        id: "IDRTB",
        url: "https://rupiahtoken.com/",
        name: "Bep2IDRT-ON-TT",
        tokenAddress: "0xB162CfBbc4821FB33Fa83e3050240dD920dDa230",
        decimalPlace: 8,
        exchangeAddress: "0x0528ba5bF75d02B259cA442cfdD2331a829EdE00",
    },
    {
        "id": "BNB",
        url: "https://www.binance.org/",
        "name": "BNB-ON-TT",
        "tokenAddress": "0x64D8723FFA3B145BAdd7a6896C966fD11CD8c7F1",
        "decimalPlace": 8,
        "exchangeAddress": "0xe0934D6b6d50124fcbAeEb96251560C6eA4b9d50"
    },
    {
        "id": "ETH-B",
        url: "https://www.ethereum.org/",
        "name": "Bep2ETH-ON-TT",
        "tokenAddress": "0x7DA201EC21E2cD2B79B36C16C0CBAC131504c1FC",
        "decimalPlace": 8,
        "exchangeAddress": "0xCCd54EfC1298565f949465B819f97bD7f67De9D1"
    },
    {
        "id": "BTC-B",
        url: "https://www.bitcoin.org/",
        "name": "Bep2BTC-ON-TT",
        "tokenAddress": "0x8a8A8568C27B29550fF5f877016662EFD47828C8",
        "decimalPlace": 8,
        "exchangeAddress": "0xb5b6F02A4C37DE9Ab235f74Dac59C93dA536C3Aa"
    },
    {
        "id": "TT-ETH",
        url: "https://www.ethereum.org/",
        "name": "TT-ETH",
        "tokenAddress": "0x6576Bb918709906DcbFDCeae4bB1e6df7C8a1077",
        "decimalPlace": 18,
        "exchangeAddress": "0x458a9FE2E71e56e79205904AB132e3e32160250c"
    },
    {
        "id": "TT-WBTC",
        url: "https://www.bitcoin.org",
        "name": "TT-WBTC",
        "tokenAddress": "0x18fB0A62f207A2a082cA60aA78F47a1af4985190",
        "decimalPlace": 8,
        "exchangeAddress": "0x2FCdef417dF4A274698e9f641c61b4FC2E216Dd1"
    },
    {
        "id": "TRX",
        url: "https://tron.network/",
        "name": "Bep2TRX-ON-TT",
        "tokenAddress": "0x0d246F3a3adF106ee8e204f28F2d340D86bF4Db0",
        "decimalPlace": 8,
        "exchangeAddress": "0x77CD4f974Df0D36a78D1A7E30427A48Ac89Aa2dB"
    },
    {
        "id": "DOT",
        url: "https://polkadot.network/",
        "name": "Bep2DOT-ON-TT",
        "tokenAddress": "0xaEaCF8C931b45987506Ade0D19d41ea822e19A76",
        "decimalPlace": 8,
        "exchangeAddress": "0x85EB839D10aECCf26eF2C48257338eBE7b63e3cC"
    },
    {
        id: "RISK",
        url: "https://playdice.xyz/risk",
        name: "Serious Risk",
        tokenAddress: "0x137222fF76855E3804d7C2B345B2fB1d9d7591Ef",
        decimalPlace: 18,
        exchangeAddress: "0xbA422a25C8AA0e81724f2b593d4384fa19bFA3a2"
    },
    {
        id: "CARE",
        url: "https://playdice.xyz",
        name: "Careful V4",
        tokenAddress: "0x62839205805a96F8b4122d4eCD028999E79FBd27",
        decimalPlace: 18,
        exchangeAddress: "0x4381E8cdEd53a6182A37C85DFcCC9D252245e1C8",
    },
    {
        id: "SET",
        url: "https://playdice.xyz",
        name: "Seriously",
        tokenAddress: "0xbb9a3CC287a4060B06257435906E0491305C112C",
        decimalPlace: 18,
        exchangeAddress: "0x16AB55c96596Cc926932AD3D71290D08ad932c09",
    },
    {
        id: "NICER",
        url: "https://nicer.bet",
        name: "NICER",
        tokenAddress: "0x1F489E0282cFA883A4224C91309bC4D4c062ed93",
        decimalPlace: 18,
        exchangeAddress: "0x14aa2F18D0160ea76BFf76c141A8dFca328FE676",
    },
    {
        id: "RISER",
        url: "https://riserdefi.com",
        name: "RISER",
        tokenAddress: "0xC1E7000f379f2247Ae930ba98d5568cd9D0b924b",
        decimalPlace: 18,
        exchangeAddress: "0xf406D475ABDAc65D45D9bCDbB356993D8D89b8Bf",
    },
]