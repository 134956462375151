
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import Base from "./Base";
import Balance from "../components/balance.vue";
import Big from "bignumber.js";
import { BigNumber } from "ethers/utils";
import { getContract, txFee } from "../lib/blockchain-helper";
import { JsonRpcProvider, TransactionReceipt } from "ethers/providers";
import debounce from "lodash/debounce";
import { Contract, ethers } from "ethers";
import NumberDisplay from "../components/number-display.vue";
import { AddressZero } from "ethers/constants";
import network from "../network";

@Component({
    components: { Balance, NumberDisplay },
})
export default class InnerLiquidity extends Base {
    @Prop({ required: true })
    readonly provider: JsonRpcProvider | undefined;

    @Prop({ required: true })
    readonly coins: any[] | undefined;

    liquidity = {
        amount: "0",
        amountTT: "0",
        coin: { id: 0 },
    };

    @Prop({ required: true })
    readonly liquidityLoading: boolean | undefined;

    @Prop({ required: true })
    readonly fetchLiquidity: Function | undefined;

    @Prop({ required: true })
    readonly liquidities:
        | {
            [tokenId: string]: {
                major: Big;
                minor: Big;
            };
        }
        | undefined;

    searchCoin = "";

    loading = false;
    tab = "my"; // add / my

    lpWatchers: {
        [coinId: string]: {
            contract: Contract;
            balance: Big;
            totalSupply: Big;
        };
    } = {};

    // helper to reload balances..
    balanceShow = {
        liquidity: "",
    };

    enteredLiquidity = {
        coin: { id: 0 },
        range: 0,
        lpToken: new Big(0),
        removeAmount: "0",
    };

    beforeDestroy() {
        this.cleanUp();
    }

    mounted() {
        this.loadLpWatchers();
    }

    toBig(v: any) {
        return new Big(v);
    }

    cleanUp() {
        for (const key in this.lpWatchers) {
            this.lpWatchers[key].contract.removeAllListeners("Transfer");
        }
    }

    watcherMutex = Promise.resolve();

    @Watch("coins")
    async onCoinChange() {
        // why mutex? because coins is called multiple times when coins are changing
        await this.watcherMutex;
        this.watcherMutex = this.loadLpWatchers();
    }

    myLpCount() {
        let i = 0;
        for (const key in this.lpWatchers) {
            if (this.lpWatchers[key].balance.gt(0)) ++i;
        }
        return i;
    }

    forceLoadLpWatchers() {
        this.loading = true;
        this.$nextTick(async function () {
            try {
                await this.loadLpWatchers();
            } catch (error) {
                let msg;
                if (error.data && error.data.message) msg = error.data.message;
                else {
                    msg = error.message || error.toString();
                }
                console.trace(error);
                alert(msg);
            } finally {
                this.loading = false;
            }
        });
    }

    // for monitoring user lptoken's balance
    async loadLpWatchers() {
        // id: string;
        // name: string;
        // tokenAddress: string;
        // decimalPlace: number;
        // exchangeAddress: string;

        if (
            this.coins &&
            network &&
            this.provider &&
            this.liquidities &&
            this.fetchLiquidity
        ) {
            const p = this.getProvider(false);
            if (!p) {
                return;
            }

            const provider = p;
            const signer = provider.getSigner();

            let account: string;
            try {
                account = await signer.getAddress();
            } catch (error) {
                return;
            }

            for (const coin of this.coins) {
                if (!(coin.id && coin.exchangeAddress)) continue;

                if (!this.lpWatchers[coin.id]) {
                    const contract = getContract(
                        network,
                        "Swapper",
                        this.provider,
                        coin.exchangeAddress
                    );

                    const b: BigNumber = await contract.balanceOf(account);
                    const balance = new Big(b.toString()).div(1e18);

                    const ts: BigNumber = await contract.totalSupply();
                    const totalSupply = new Big(ts.toString()).div(1e18);

                    if (balance.gt(0)) {
                        if (!this.liquidities[coin.id]) {
                            await this.fetchLiquidity(
                                coin.id,
                                coin.exchangeAddress,
                                coin.tokenAddress,
                                coin.decimalPlace
                            );
                        }
                    }

                    this.lpWatchers = {
                        ...this.lpWatchers,
                        [coin.id]: { contract, balance, totalSupply },
                    };

                    console.log(
                        "watching lp",
                        await contract.name(),
                        await contract.symbol()
                    );

                    contract.on(
                        "Transfer",
                        async (from: string, to: string) => {
                            if (from == account || to == account) {
                                const b: BigNumber = await contract.balanceOf(
                                    account
                                );
                                const balance = new Big(b.toString()).div(1e18);

                                this.lpWatchers[coin.id].balance = balance;
                            }
                            if (from == AddressZero || to == AddressZero) {
                                const ts: BigNumber =
                                    await contract.totalSupply();

                                this.lpWatchers[coin.id].totalSupply = new Big(
                                    ts.toString()
                                ).div(1e18);
                            }
                        }
                    );
                }
            }
        }
    }

    showSelectCoin() {
        const win = window as any;
        win.jQuery("#select-coin2").modal("show");
    }

    selectCoin(coin: any) {
        this.liquidity.coin = coin;

        if (this.fetchLiquidity)
            this.fetchLiquidity(
                coin.id,
                coin.exchangeAddress,
                coin.tokenAddress,
                coin.decimalPlace
            );

        const win = window as any;
        win.jQuery("#select-coin2").modal("hide");
    }

    ttClicked(n: string) {
        if (!this.liquidities) return;

        const amount = new Big(n).minus(0.002);

        const l = this.liquidities[this.liquidity.coin.id];

        if (l && amount.gt(0)) {
            this.liquidity.amount = amount
                .times(l.minor.div(l.major))
                .dp(6)
                .toString();
        }
    }

    filteredCoins() {
        if (!this.coins) return [];

        if (!this.searchCoin) return this.coins.filter((v) => v.id != "TT");

        const searchCoin = this.searchCoin.toUpperCase();

        return this.coins.filter((v) => {
            return (
                v.name &&
                (v.id.toString().toUpperCase().includes(searchCoin) ||
                    v.name.toUpperCase().includes(searchCoin))
            );
        });
    }

    startAddLiquidity(coin: any) {
        this.selectCoin(coin);
        this.tab = "add";
        this.liquidity.amount = "";
    }

    async startRemoveLiquidity(coin: any) {
        if (!this.lpWatchers[coin.id]) return;

        this.enteredLiquidity.lpToken = this.lpWatchers[coin.id].balance;
        this.enteredLiquidity.coin = coin;
        this.enteredLiquidity.range = 0;

        const win = window as any;
        win.jQuery("#remove-liquidity-modal").modal("show");
    }

    removeLiquidity() {
        this.loading = true;
        this.$store.commit("blockchainProgress", "removing liquidity");

        this.$nextTick(async function () {
            try {
                if (!(network && this.provider))
                    throw "network | provider is not set";

                const p = this.getProvider(false);

                if (!p) return;

                const provider = p;
                const signer = provider.getSigner();

                let account;
                try {
                    account = await signer.getAddress();
                } catch (error) {
                    throw "no account";
                }

                const swapper = getContract(
                    network,
                    "Swapper",
                    signer,
                    (this.enteredLiquidity.coin as any).exchangeAddress
                );

                const amount = new Big(this.enteredLiquidity.lpToken)
                    .times(this.enteredLiquidity.range / 100)
                    .times(1e18)
                    .dp(0, Big.ROUND_DOWN);

                // console.log(`amount: ${amount.toFormat()}`);

                this.$store.commit(
                    "blockchainProgress",
                    "waiting for liquidity removal confirmation"
                );

                // const lpValue = await swapper.lpValue(amount.toString());
                // console.log(`account: ${account}, swapper: ${swapper.address}, tt: ${this.toEther(lpValue[0], 18)}, token: ${this.toEther(lpValue[1], 18)}`);

                const t = await swapper.removeLiquidity(amount.toString(), { ...txFee() });

                this.$store.commit(
                    "blockchainProgress",
                    "waiting for blockchain confirmation"
                );

                const r = await this.provider.waitForTransaction(t.hash);

                if (r.status) {
                    this.enteredLiquidity.removeAmount = "0";

                    const win = window as any;
                    win.jQuery("#remove-liquidity-modal").modal("hide");
                }
            } catch (error) {
                let msg;
                if (error.data && error.data.message) msg = error.data.message;
                else {
                    msg = error.message || error.toString();
                }
                console.trace(error);
                alert(msg);
            } finally {
                this.loading = false;
                this.$store.commit("blockchainProgress", "");
            }
        });
    }

    async addLiquidity() {
        this.loading = true;
        this.$store.commit("blockchainProgress", "adding liquidity");

        this.$nextTick(async function () {
            try {
                if (!(network && this.provider && this.liquidities))
                    throw "network|provider|liquidities is not defined";

                if (new Big(this.liquidity.amount).lte(0))
                    return alert("Token Amount not specified");

                if (!this.liquidity.coin.id) throw "no coin specified";

                const l = this.liquidities[this.liquidity.coin.id];

                if (!l) throw "token liquidity is not loaded";

                const currentPrice = l.minor.div(l.major);

                // token is not priced yet
                let TTAmount = new Big(this.liquidity.amountTT)
                    .times(1e18)
                    .dp(0, Big.ROUND_DOWN);

                // we already have price for TT
                if (currentPrice.gt(0)) {
                    TTAmount = new Big(this.liquidity.amount)
                        .div(currentPrice)
                        .times(1e18)
                        .dp(0, Big.ROUND_DOWN);
                }

                const p = this.getProvider(false);
                if (!p) return;
                const provider = p;
                const signer = provider.getSigner();

                let account;
                try {
                    account = await signer.getAddress();
                } catch (error) {
                    throw "no account";
                }

                const swapper = getContract(
                    network,
                    "Swapper",
                    signer,
                    (this.liquidity.coin as any).exchangeAddress
                );

                const erc20 = getContract(
                    network,
                    "ERC20",
                    signer,
                    (this.liquidity.coin as any).tokenAddress
                );

                const decimals = await erc20.decimals();

                // check for approval
                const allowance_ = await erc20.allowance(
                    account,
                    swapper.address
                );

                const allowance = new Big(allowance_.toString()).div(
                    `1e${decimals}`
                );

                // request for allowance..
                if (allowance.lt(this.liquidity.amount)) {
                    this.$store.commit(
                        "blockchainProgress",
                        `[unlocking] waiting permission for contract to spend ${this.liquidity.coin.id}`
                    );
                    const t = await erc20.approve(
                        swapper.address,
                        new Big(this.liquidity.amount)
                            .times(1e12)
                            .times(`1e${decimals}`)
                            .dp(0, Big.ROUND_DOWN)
                            .toString(),
                        { ...txFee() }
                    );

                    this.$store.commit(
                        "blockchainProgress",
                        `[unlocked ${this.liquidity.coin.id}] waiting for blockchain confirmation`
                    );
                    await t.wait(1);
                }

                const amountEther = new Big(this.liquidity.amount)
                    .times(`1e${decimals}`)
                    .dp(0, Big.ROUND_DOWN);

                this.$store.commit(
                    "blockchainProgress",
                    "waiting for adding liquidity confirmation"
                );

                // current liquidity
                const currentLiquidity = await swapper.currentLiquidity();
                console.log(
                    `current liquidity major: ${this.toEther(
                        currentLiquidity[0]
                    )}, minor: ${this.toEther(currentLiquidity[1])}`
                );

                const t = await swapper.addLiquidity(amountEther.toString(), {
                    value: ethers.utils.bigNumberify(TTAmount.toString()),
                    ...txFee()
                });

                this.$store.commit(
                    "blockchainProgress",
                    "waiting for blockchain confirmation"
                );

                const r = await this.provider.waitForTransaction(t.hash);

                if (r.status) {
                    this.tab = "my";
                    this.liquidity.amount = "";

                    if (r.logs) {
                        // event LiquidityAdded(address indexed sender, uint256 lpToken, uint256 native, uint256 token);
                        const events = r.logs
                            .map((v) => swapper.interface.parseLog(v))
                            .filter((v) => v && v.name == "LiquidityAdded");

                        const liquidityAdded = events[0];

                        if (liquidityAdded) {
                            console.log(
                                `address ${liquidityAdded.values.sender
                                }, share: ${this.toEther(
                                    liquidityAdded.values.lpToken
                                )}, native: ${this.toEther(
                                    liquidityAdded.values.native
                                )}, token: ${this.toEther(
                                    liquidityAdded.values.token
                                )}`
                            );
                        }
                    }

                    return this.forceLoadLpWatchers();
                }
            } catch (error) {
                let msg;
                if (error.data && error.data.message) msg = error.data.message;
                else {
                    msg = error.message || error.toString();
                }
                console.trace(error);
                alert(msg);
            } finally {
                this.loading = false;
                this.$store.commit("blockchainProgress", "");
            }
        });
    }
}
