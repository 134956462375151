
import { JsonRpcProvider } from "ethers/providers";
import Base from "./Base";
import { Component, Prop, Watch } from "vue-property-decorator";
import Balance from "../components/balance.vue";
import Big from "bignumber.js";
import { getContract, txFee } from "../lib/blockchain-helper";
import { BigNumber } from "ethers/utils";
import { ethers } from "ethers";
import NumberDisplay from "../components/number-display.vue";
import network from "../network";

enum SwapActions {
    swapToMinor = "swapToMinor",
    swapToMajor = "swapToMajor",
    crossSwap = "crossSwap",
}

type Coin = {
    id: string | number;
    name?: string;
    tokenAddress?: string;
    decimalPlace?: number;
    exchangeAddress?: string;
};

function getAmountOut(amountIn: Big, reserveIn: Big, reserveOut: Big) {
    const amountInWithFee = amountIn.times(998);
    return amountInWithFee
        .times(reserveOut)
        .div(reserveIn.times(1000).plus(amountInWithFee));
}

function getAmountIn(amountOut: Big, reserveIn: Big, reserveOut: Big) {
    return reserveIn
        .times(amountOut)
        .times(1000)
        .div(reserveOut.minus(amountOut).times(998));
}

@Component({
    components: { Balance, NumberDisplay },
})
export default class SwapInner extends Base {
    @Prop({ required: true })
    readonly provider: JsonRpcProvider | undefined;

    @Prop({ required: true })
    readonly coins: Coin[] | undefined;

    @Prop({ required: true })
    readonly liquidityLoading: boolean | undefined;

    @Prop({ required: true })
    readonly fetchLiquidity: Function | undefined;

    @Prop({ required: true })
    readonly liquidities:
        | {
            [tokenId: string]: {
                major: Big;
                minor: Big;
            };
        }
        | undefined;

    searchCoin = "";

    source: { amount: string; coin: Coin } = {
        amount: "0",
        coin: { id: 0 },
    };

    focusAt = "";

    dest: { amount: string; coin: Coin } = {
        amount: "0",
        coin: { id: 0 },
    };

    balanceShow = {
        source: "",
        dest: "",
    };

    loading = false;

    usdPerTT = new Big(0);
    usdPerTTAfterSwap = new Big(0);
    receiveTokenAfterSwap = new Big(0);
    minReceiveTokenAfterSwap = new Big(0);
    swapFeePercent = new Big(0.2);
    pricePrefix = "";

    slippagePercent = "3";

    modalSelectCoinFor = ""; // source / destination

    swapOutput = {
        priceBeforeSwap: new Big(0),
        priceImpactPercent: new Big(0),
        swapFeePercent: new Big(0),
        swapFee: new Big(0),
    };

    @Watch("source.coin")
    onSourceCoinChanged() {
        this.balanceShow.source = "";

        this.focusAt = "source";
        this.onSourceAmountChanged();

        this.$nextTick(function () {
            this.balanceShow.source = (this.source.coin as any).tokenAddress;
        });
    }

    @Watch("dest.coin")
    onDestCoinChanged() {
        this.balanceShow.dest = "";

        this.focusAt = "dest";
        this.onDestAmountChanged();

        this.$nextTick(function () {
            this.balanceShow.dest = (this.dest.coin as any).tokenAddress;
        });
    }

    @Watch("source.amount")
    onSourceAmountChanged() {
        if (this.focusAt == "source") {
            if (
                !(
                    this.source.coin.id &&
                    this.dest.coin.id &&
                    this.source.coin.decimalPlace != undefined &&
                    this.dest.coin.decimalPlace != undefined &&
                    this.liquidities
                )
            )
                return;

            const swapAction = this.getSwapAction();
            let output = new Big(0);
            let amountIn = new Big(this.source.amount);

            const path: string[] = [this.source.coin.id.toString()];

            if (swapAction == "crossSwap") {
                path.push("TT");
            }

            path.push(this.dest.coin.id.toString());

            const liquidities = this.getLiquidity(path);

            const pricesBeforeSwap: Big[] = [];
            const pricesAfterSwap: Big[] = [];
            const impacts: Big[] = [];

            for (const index in liquidities) {
                const idx = parseInt(index);
                if (idx + 1 >= path.length) break;

                const liq = liquidities[idx];
                const reserveIn = this.getReserve(path[idx], liq);
                const reserveOut = this.getReserve(path[idx + 1], liq);

                output = getAmountOut(amountIn, reserveIn, reserveOut);

                const pBefore = reserveOut.div(reserveIn);
                const pAfter = reserveOut
                    .minus(output)
                    .div(reserveIn.plus(amountIn));
                const impact = pAfter.minus(pBefore).div(pBefore);

                pricesBeforeSwap.push(pBefore);
                pricesAfterSwap.push(pAfter);
                impacts.push(impact.times(100).abs());

                amountIn = output;
            }

            this.dest.amount = output
                .dp(this.dest.coin.decimalPlace, Big.ROUND_DOWN)
                .toString();

            if (liquidities.length > 0) {
                this.swapOutput.swapFeePercent = new Big(
                    liquidities.length
                ).times(0.2);

                const n998 = new Big(100)
                    .minus(this.swapOutput.swapFeePercent)
                    .div(100);

                this.swapOutput.swapFee = output
                    .div(n998)
                    .times(this.swapOutput.swapFeePercent.div(100));

                this.swapOutput.priceImpactPercent = Big.max(...impacts);
            }
        }
    }

    @Watch("dest.amount")
    onDestAmountChanged() {
        if (this.focusAt == "dest") {
            if (
                !(
                    this.source.coin.id &&
                    this.dest.coin.id &&
                    this.source.coin.decimalPlace != undefined &&
                    this.dest.coin.decimalPlace != undefined &&
                    this.liquidities
                )
            )
                return;

            const swapAction = this.getSwapAction();

            console.log("dest amount changed");

            let input = new Big(0);
            let amountOut = new Big(this.dest.amount);

            const path: string[] = [this.dest.coin.id.toString()];

            if (swapAction == "crossSwap") {
                path.push("TT");
            }

            path.push(this.source.coin.id.toString());

            const liquidities = this.getLiquidity(path);

            const pricesBeforeSwap: Big[] = [];
            const pricesAfterSwap: Big[] = [];
            const impacts: Big[] = [];

            for (const index in liquidities) {
                const idx = parseInt(index);
                if (idx + 1 >= path.length) break;

                const liq = liquidities[idx];
                const reserveIn = this.getReserve(path[idx + 1], liq);
                const reserveOut = this.getReserve(path[idx], liq);

                input = getAmountIn(amountOut, reserveIn, reserveOut);

                const pBefore = reserveOut.div(reserveIn);
                const pAfter = reserveOut
                    .minus(amountOut)
                    .div(reserveIn.plus(input));

                const impact = pAfter.minus(pBefore).div(pBefore);

                pricesBeforeSwap.push(pBefore);
                pricesAfterSwap.push(pAfter);
                impacts.push(impact.times(100).abs());

                amountOut = input;
            }

            this.source.amount = input
                .dp(this.source.coin.decimalPlace, Big.ROUND_DOWN)
                .toString();

            if (liquidities.length > 0) {
                this.swapOutput.swapFeePercent = new Big(
                    liquidities.length
                ).times(0.2);

                const n998 = new Big(100)
                    .minus(this.swapOutput.swapFeePercent)
                    .div(100);

                this.swapOutput.swapFee = new Big(this.dest.amount)
                    .div(n998)
                    .times(this.swapOutput.swapFeePercent.div(100));

                this.swapOutput.priceImpactPercent = Big.max(...impacts);
            }
        }
    }

    @Watch("liquidities")
    onLiquiditiesChanged() {
        // re-calculate output..
        console.log("liquidities changed..", this.focusAt);

        if (this.focusAt == "source") {
            this.onSourceAmountChanged();
        } else {
            this.onDestAmountChanged();
        }
    }

    mounted() {
        if (this.coins && this.coins.length > 0) {
            this.source.coin = this.coins[0];
        }
    }

    toBig(v: any) {
        return new Big(v);
    }

    filteredCoins() {
        if (!this.coins) return [];

        if (!this.searchCoin) return this.coins;

        const searchCoin = this.searchCoin.toUpperCase();

        return this.coins.filter((v) => {
            return (
                v.name &&
                (v.id.toString().toUpperCase().includes(searchCoin) ||
                    v.name.toUpperCase().includes(searchCoin))
            );
        });
    }

    showSelectCoin(which: string) {
        this.modalSelectCoinFor = which;
        const win = window as any;
        win.jQuery("#select-coin").modal("show");
    }

    selectCoin(coin: any) {
        if (this.fetchLiquidity && coin.id != "TT") {
            this.fetchLiquidity(
                coin.id,
                coin.exchangeAddress,
                coin.tokenAddress,
                coin.decimalPlace
            );
        }

        if (this.modalSelectCoinFor == "source") {
            this.source.coin = coin;
        } else {
            this.dest.coin = coin;
        }

        const win = window as any;
        win.jQuery("#select-coin").modal("hide");
    }

    swapSourceAndDest() {
        this.focusAt = "dest";
        const destCoin = this.dest.coin;
        const destAmount = this.dest.amount;
        const sourceAmount = this.source.amount;

        this.dest.coin = this.source.coin;

        if (this.focusAt == "source") {
            this.focusAt = "dest";
            this.dest.amount = sourceAmount;
        }

        this.source.coin = destCoin;

        if (this.focusAt == "dest") {
            this.focusAt = "source";
            this.source.amount = destAmount;
        }
    }

    showSlippageSettings() {
        const win = window as any;
        win.jQuery("#slippage-setting").modal("show");
    }

    doSwap() {
        this.loading = true;
        this.$store.commit("blockchainProgress", "start swapping");

        this.$nextTick(async function () {
            try {
                if (
                    !(
                        this.provider &&
                        this.dest.coin.decimalPlace != undefined &&
                        this.source.coin.decimalPlace != undefined
                    )
                )
                    return;

                const swapAction = this.getSwapAction();

                const p = this.getProvider(false);

                if (!p) {
                    return;
                }

                const provider = p;
                const signer = provider.getSigner();
                let account;
                try {
                    account = await signer.getAddress();
                } catch (error) {
                    throw "no account";
                }

                const router = getContract(network, "Router", signer);
                const swapper = getContract(network, "Swapper", signer);
                const erc20 = getContract(network, "EGG", signer);

                const minReceive = new Big(100)
                    .minus(this.slippagePercent)
                    .div(100)
                    .times(this.dest.amount)
                    .times(`1e${this.dest.coin.decimalPlace}`)
                    .dp(0, Big.ROUND_DOWN);

                // deadline is 2 minute
                const deadline = new Big(Date.now())
                    .div(1000)
                    .dp(0)
                    .plus(2 * 60)
                    .toNumber();

                switch (swapAction) {
                    case SwapActions.swapToMinor:
                        {
                            const value = new Big(this.source.amount)
                                .times(1e18)
                                .dp(0, 1);

                            this.$store.commit(
                                "blockchainProgress",
                                "waiting for approval"
                            );

                            console.log(
                                `min receive: ${minReceive}, deadline: ${deadline}, dp: ${this.dest.coin.decimalPlace}`
                            );

                            const t = await router.swapToMinor(
                                (this.dest.coin as any).tokenAddress,
                                minReceive.toString(),
                                deadline,
                                {
                                    value: ethers.utils.bigNumberify(
                                        value.toString()
                                    ),
                                    ...txFee()
                                }
                            );

                            const dp = (this.dest.coin as any).decimalPlace;

                            console.log(t);

                            this.$store.commit(
                                "blockchainProgress",
                                "waiting for blockchain confirmation"
                            );

                            const r = await this.provider.waitForTransaction(
                                t.hash
                            );

                            if (r.logs) {
                                const transfers = r.logs
                                    .map((log) => erc20.interface.parseLog(log))
                                    .filter((v) => v && v.name == "Transfer")
                                    .map((v) => {
                                        return {
                                            from: v.values.from,
                                            to: v.values.to,
                                            value: new Big(
                                                v.values.value.toString()
                                            )
                                                .div(`1e${dp}`)
                                                .toNumber(),
                                        };
                                    });

                                console.log(transfers);

                                const swaperLogs = r.logs
                                    .map((log) =>
                                        swapper.interface.parseLog(log)
                                    )
                                    .filter((v) => v);

                                console.log(swaperLogs);

                                const swapped = swaperLogs.filter(
                                    (v) => v.name == "Swapped"
                                )[0];

                                if (swapped) {
                                    const amountNative = new Big(
                                        swapped.values.amountNative
                                    ).div(1e18);
                                    const amountToken = new Big(
                                        swapped.values.amountToken
                                    ).div(`1e${this.dest.coin.decimalPlace}`);

                                    window.swal(
                                        "info",
                                        `swapped ${amountNative
                                            .dp(6)
                                            .toFormat()} TT for ${amountToken
                                                .dp(6)
                                                .toFormat()} ${this.dest.coin.id}`
                                    );
                                }
                            }
                        }
                        break;
                    case SwapActions.swapToMajor:
                        {
                            const dp = (this.source.coin as any).decimalPlace;
                            const amount = new Big(this.source.amount)
                                .times(`1e${dp}`)
                                .dp(0, 1);

                            // console.log(
                            //     "token address",
                            //     (this.source.coin as any).tokenAddress
                            // );
                            // console.log("amount", amount.toString());

                            this.$store.commit(
                                "blockchainProgress",
                                "fetching swapper address"
                            );

                            const tid = await router.tokenAddressToId(
                                (this.source.coin as any).tokenAddress
                            );
                            // console.log("tid:", tid.toString());

                            const swapperAddress = await router.exchanges(tid);
                            console.log("swapper:", swapperAddress);

                            const swapper = getContract(
                                network,
                                "Swapper",
                                signer,
                                swapperAddress
                            );

                            const usd = getContract(
                                network,
                                "ERC20",
                                signer,
                                (this.source.coin as any).tokenAddress
                            );

                            const allowance: BigNumber = await usd.allowance(
                                account,
                                swapperAddress
                            );

                            if (allowance.lt(amount.toString())) {
                                this.$store.commit(
                                    "blockchainProgress",
                                    `[unlocking] waiting permission for contract to spend ${this.source.coin.id}`
                                );

                                const t = await usd.approve(
                                    swapperAddress,
                                    amount.times(1e12).toString(),
                                    { ...txFee() }
                                );

                                this.$store.commit(
                                    "blockchainProgress",
                                    `[unlocked ${this.source.coin.id}] waiting for blockchain confirmation`
                                );
                                await t.wait(1);
                            }

                            this.$store.commit(
                                "blockchainProgress",
                                "waiting for swap confirmation"
                            );

                            const t = await router.swapToMajor(
                                (this.source.coin as any).tokenAddress,
                                amount.toString(),
                                minReceive.toString(),
                                deadline,
                                { ...txFee() }
                            );

                            console.log(t);

                            this.$store.commit(
                                "blockchainProgress",
                                "waiting for blockchain confirmation"
                            );

                            const r = await this.provider.waitForTransaction(
                                t.hash
                            );

                            if (r.logs) {
                                const transfers = r.logs
                                    .map((log) => erc20.interface.parseLog(log))
                                    .filter((v) => v && v.name == "Transfer")
                                    .map((v) => {
                                        return {
                                            from: v.values.from,
                                            to: v.values.to,
                                            value: new Big(
                                                v.values.value.toString()
                                            )
                                                .div(`1e${dp}`)
                                                .toNumber(),
                                        };
                                    });

                                console.log(transfers);

                                const swaperLogs = r.logs
                                    .map((log) =>
                                        swapper.interface.parseLog(log)
                                    )
                                    .filter((v) => v);

                                console.log(swaperLogs);

                                const swapped = swaperLogs.filter(
                                    (v) => v.name == "Swapped"
                                )[0];

                                if (swapped) {
                                    const amountNative = new Big(
                                        swapped.values.amountNative
                                    ).div(1e18);
                                    const amountToken = new Big(
                                        swapped.values.amountToken
                                    ).div(`1e${this.source.coin.decimalPlace}`);

                                    window.swal(
                                        "info",
                                        `swapped ${amountToken
                                            .dp(6)
                                            .toFormat()} ${this.source.coin.id
                                        } for ${amountNative
                                            .dp(6)
                                            .toFormat()} TT`
                                    );
                                }
                            }
                        }
                        break;
                    case SwapActions.crossSwap:
                        {
                            const erc20 = getContract(
                                network,
                                "ERC20",
                                signer,
                                this.source.coin.tokenAddress
                            );

                            const allowance: BigNumber = await erc20.allowance(
                                account,
                                router.address
                            );
                            const a = new Big(allowance.toString()).div(
                                `1e${this.source.coin.decimalPlace}`
                            );

                            const amountInEther = new Big(this.source.amount)
                                .times(`1e${this.source.coin.decimalPlace}`)
                                .dp(0, Big.ROUND_DOWN);

                            if (a.lt(this.source.amount)) {
                                this.$store.commit(
                                    "blockchainProgress",
                                    `[unlocking] waiting permission for contract to spend ${this.source.coin.id}`
                                );

                                const t = await erc20.approve(
                                    router.address,
                                    amountInEther.times(1e12).toString(),
                                    { ...txFee() }
                                );

                                this.$store.commit(
                                    "blockchainProgress",
                                    `[unlocked ${this.source.coin.id}] waiting for blockchain confirmation`
                                );
                                await t.wait(1);
                            }

                            this.$store.commit(
                                "blockchainProgress",
                                "waiting for swap confirmation"
                            );

                            const t = await router.crossSwap(
                                this.source.coin.tokenAddress,
                                this.dest.coin.tokenAddress,
                                amountInEther.toString(),
                                minReceive.toString(),
                                deadline,
                                { ...txFee() }
                            );

                            this.$store.commit(
                                "blockchainProgress",
                                "waiting for blockchain confirmation"
                            );

                            const r = await this.provider.waitForTransaction(
                                t.hash
                            );

                            if (r.logs) {
                                const swaperLogs = r.logs
                                    .map((log) =>
                                        swapper.interface.parseLog(log)
                                    )
                                    .filter((v) => v);

                                // console.log(swaperLogs);

                                const swapped = swaperLogs.filter(
                                    (v) => v.name == "Swapped"
                                );

                                if (swapped.length == 2) {
                                    const inToken = new Big(
                                        swapped[0].values.amountToken.toString()
                                    ).div(`1e${this.source.coin.decimalPlace}`);

                                    const outToken = new Big(
                                        swapped[1].values.amountToken.toString()
                                    ).div(`1e${this.dest.coin.decimalPlace}`);

                                    window.swal(
                                        "info",
                                        `swapped ${inToken.dp(6).toFormat()} ${this.source.coin.id
                                        } for ${outToken.dp(6).toFormat()} ${this.dest.coin.id
                                        }`
                                    );
                                }
                            }
                        }

                        break;
                }

                this.source.amount = "0";
                this.dest.amount = "0";
            } catch (error) {
                let msg;
                if (error.data && error.data.message) msg = error.data.message;
                else {
                    msg = error.message || error.toString();
                }
                console.trace(error);
                alert(msg);
            } finally {
                this.loading = false;
                this.$store.commit("blockchainProgress", "");
            }
        });
    }

    getSwapAction() {
        let swapAction: SwapActions;

        if (this.source.coin.id != "TT" && this.dest.coin.id != "TT") {
            swapAction = SwapActions.crossSwap;
        } else {
            if (this.source.coin.id == "TT") {
                swapAction = SwapActions.swapToMinor;
            } else {
                swapAction = SwapActions.swapToMajor;
            }
        }

        return swapAction;
    }

    getLiquidity(path: string[]) {
        const out: { major: Big; minor: Big }[] = [];

        if (this.source.coin.id && this.dest.coin.id && this.liquidities) {
            const coins = [path[0], path[path.length - 1]];

            for (const coin of coins) {
                if (coin != "TT") {
                    const l = this.liquidities[coin];
                    if (l) {
                        out.push(l);
                    }
                }
            }
        }

        return out;
    }

    getReserve(
        coinId: string,
        liquidity: { major: Big; minor: Big } | undefined
    ) {
        if (coinId && liquidity) {
            if (coinId == "TT") {
                return liquidity.major;
            } else {
                return liquidity.minor;
            }
        }

        return new Big(0);
    }

    usdtPrice() {
        return false;
    }
}
