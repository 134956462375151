import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Swap from "../views/Swap.vue";

import store from "../store"

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "SwapHome",
    component: Swap
  },
  // {
  //   path: "/",
  //   name: "HomeUpgradeEGG",
  //   component: () => import("../views/EggUpgrade.vue")
  // },
  {
    path: "/swap",
    name: "Swap",
    component: Swap
  },
  {
    path: "/farm",
    name: "Farm",
    component: () => import("../views/Farm.vue")
  },
  // {
  //   path: "/stake-simulation",
  //   name: "StakeSimulation",
  //   component: () => import("../views/StakeSimulation.vue")
  // },
  {
    path: "/wallet",
    name: "Wallet",
    component: () => import("../views/Wallet.vue")
  },
  {
    path: "/adm",
    name: "Adm",
    component: () => import("../views/Adm.vue")
  },
  // {
  //   path: "/upgrade",
  //   name: "UpgradeEGG",
  //   component: () => import("../views/EggUpgrade.vue")
  // },
  {
    path: "/info",
    name: "Info",
    component: () => import("../views/Info.vue")
  },
  {
    path: "/chart/:coinId",
    name: "Chart",
    component: () => import("../views/info/chart.vue")
  },
  {
    path: "/arbit",
    name: "Arbit",
    component: () => import("../views/Arbit.vue")
  },
  // {
  //   path: "/trust",
  //   name: "Trust",
  //   component: () => import("../views/Trust.vue")
  // }
  // {
  //   path: "/old-lp",
  //   name: "OldLp",
  //   component: () => import("../views/OldLp.vue")
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.commit("routerLoading", true);
  next();
})

router.afterEach((to, from) => {
  store.commit("routerLoading", false);
})

export default router;
