
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Big from "bignumber.js";

@Component({})
export default class NumberDisplay extends Vue {
    @Prop()
    readonly v: string | undefined;

    @Watch("v")
    onVChange() {
        this.prepare();
    }

    beforeDigits = "";
    digits: string[] = [];

    mounted() {
        this.prepare();
    }

    prepare() {
        this.beforeDigits = "0";
        this.digits = [];

        if (!this.v) {
            return;
        }

        const strings = this.v.toString().split(".");

        this.beforeDigits = strings[0];

        let digits = strings[1];
        if (digits) {
            /// removing excess zeros..
            const b = '0.' + digits;
            digits = new Big(b).toString().split('.')[1];

            while (digits && digits.length > 0) {
                this.digits.push(digits.substring(0, 4));
                digits = digits.substring(4);
            }
        }
    }
}
